<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { paymentService } from '../../../services/payment.service';

import {
  paymentServiceMethods,
  notificationMethods
} from "@/state/helpers";

export default {
    page: {
    title: "Remittances",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Transaction Reversal",
      items: [
        {
          text: "Smart Exchange"
        },
                {
          text: "Remittances"
        },
        {
          text: "Reversals",
          active: true
        }
      ],
      transactionData: [],
      variableObject:{},
      modalTitle:"",
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "transactionReference",
      sortDesc: false,
      fields: [

        { key: "transactionId", sortable: true, label: " ID" },
        { key: "dates.dateInitiated", sortable: true, label:"Dates" },
        { key: "senderDetails",sortable: true, label: "Sender Name" },
        { key: "receiverDetails", sortable: true, label: "Receiver Name" },
        { key: "transactionDetails.amount", sortable: true, label: "Amount" },
        { key: "transactionDetails.purposeOfFunds", sortable: true, label: "Purpose" },
        { key: "status", sortable: true, label: "Status" },
        { key: "action", sortable: false },
      ],
      responseMessage:"",
      authorisationFailed: false,
      authorisationSuccess:false,
    };
  },

  created() {
    this.loadAllReversals()
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.transactionData.length;
    },

    notification() {
        return this.$store ? this.$store.state.notification : null;
      }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.transactionData.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    ...paymentServiceMethods,
    ...notificationMethods,

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async loadAllReversals() {
        try {
          await paymentService.getAllReversals().then(response=>{
            if(response.responseBody.length>0){
                this.transactionData = response.responseBody;
              }
          });
        } catch (error) {
          console.log(error);
        }
    },

      async authorizeTransaction(transaction, decision) {
            const requestBody =   {
                "operation" : "supervisor activity",
                "accessToken" : localStorage.getItem('access_token'),
                "requestBody" :
                {
                    "shopId" : JSON.parse(localStorage.getItem('user')).shopId,
                    "amount" :  transaction.amount,
                    "agentId" : JSON.parse(localStorage.getItem('user')).id,
                    "currencyType" :  transaction.currencyType,
                    "supervisorId": JSON.parse(localStorage.getItem('user')).id,
                    "transactionId" : transaction.transactionId,
                    "transactionType" : decision
                }
            };
            
            var status = false;
            try {
                await paymentService.authorizeTransactionReversal(requestBody).then(response=>{
                    if(response.status==="SUCCESS"){
                        this.authorisationSuccess = true;
                        this.responseMessage = response.message;
                        status = true;
                        this.loadAllReversals();
                    }else{
                        this.notificationType = "danger";
                        this.noticationIcon = "mdi-block-helper";
                        this.responseMessage = response.message;
                        this.authorisationFailed = true;
                        status = false;
                        this.loadAllReversals();
                    }
                });
                return status
            } catch (error) {
                this.notificationType = "danger";
                this.noticationIcon = "mdi-block-helper";
                this.authorisationFailed = true;
                this.responseMessage =error;
                console.log(error);
                return false;
            }
        },


    storeState(object, title){
      this.variableObject = object;
      this.modalTitle = title;
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


  <div class="card">
    <b-modal id="user-modal-standard" :title="modalTitle" title-class="font-18" hide-footer>
          <div class="row">
            <div class="col-xl-12">
                <p class="mb-2 text-truncate">
                  <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i>Full Name: {{variableObject.firstName + " " + variableObject.lastName}}
                </p>
                <p class="mb-2 text-truncate">
                  <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i>Phone Number: {{variableObject.phoneNumber}}
                </p>
                <p class="mb-2 text-truncate">
                  <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i>Email address: {{variableObject.email}}
                </p>
              </div>
            </div>
    </b-modal>

    <div class="card-body">
      <h5 class="card-title badge-soft-danger pb-2 pt-2 pl-2" v-if="authorisationFailed">{{responseMessage}}</h5>
      <h5 class="card-title badge-soft-success pb-2 pt-2 pl-2" v-if="authorisationSuccess">{{responseMessage}}</h5>
                    
      <h4 class="card-title mb-4">Transaction Reversals</h4>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_filter" class="dataTables_filter text-md-right">
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table
          :items="transactionData"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:cell(status)="row">
            <div
              class="badge font-size-14 badge-soft-success"
              :class="{'badge-soft-danger': `${row.item.remittanceTransactionStatus}` === 'declined',
              'badge-soft-success': `${row.item.remittanceTransactionStatus}` === 'collected',
              'badge-soft-info': `${row.item.remittanceTransactionStatus}` === 'approved',
              'badge-soft-warning': `${row.item.remittanceTransactionStatus}` === 'pending'}"
            >{{row.item.remittanceTransactionStatus==='pending'?'Deposit Pending':row.item.remittanceTransactionStatus==='approved'?'Pending Collection':String(row.item.remittanceTransactionStatus).toUpperCase()}}</div>
          </template>
          <!-- <template v-slot:cell(receiverDetails)="row">
            <div>{{row.value.receiverFirstName + ' ' + row.value.receiverDetails}}</div>
          </template> -->
          <template v-slot:cell(senderDetails)="row">
              <a
                @click="storeState(row.item.senderDetails, 'Sender Details')"
                href="javascript:void(0);"
                class="mr-3 text-primary"
                v-b-tooltip.hover
                title="Click to view"
              >
                <div v-b-modal.user-modal-standard>{{row.value.firstName + ' ' + row.value.lastName}}</div>
              </a>
          </template>
          <template v-slot:cell(receiverDetails)="row">
              <a
                @click="storeState(row.item.receiverDetails, 'Receiver Details')"
                href="javascript:void(0);"
                class="mr-3 text-primary"
                v-b-tooltip.hover
                title="Click to view"
              >
                <div v-b-modal.user-modal-standard>{{row.value.firstName + ' ' + row.value.lastName}}</div>
              </a>
          </template>
          <template v-slot:cell(action)="row">
              <a
                @click="authorizeTransaction(row.item, 'remittance-approve-reversal')"
                href="javascript:void(0);"
                class="mr-3 text-success"
                v-b-tooltip.hover
                title="Approve Reversal"
              >
                <i class="mdi mdi-check font-size-18" ></i>
              </a>
              
              <a
                @click="authorizeTransaction(row.item, 'remittance-reject-reversal')"
                href="javascript:void(0);"
                class="mr-3 text-danger"
                v-b-tooltip.hover
                title="Decline Reversal"
              >
                <i class="mdi mdi-cancel font-size-18" ></i>
              </a>

          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  </Layout> 
</template>